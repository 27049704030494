









import { Component, Vue } from 'vue-property-decorator';
import { bus } from '@/main';

@Component
export default class App extends Vue {

  mounted(){ 
      bus.$emit('hideBusy');
  }
}
